@tailwind base;
@tailwind components;
@tailwind utilities;

#progress {
    height: 20px;
    background: #1da1f2;
    box-shadow: 2px 14px 15px -7px rgba(30, 166, 250, 0.36);
    border-radius: 50px;
    transition: all 0.5s;
}

.card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.card .card-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.card .card-body {
    font-size: 14px;
    color: #666;
}

.card .card-subtitle {
    font-size: 16px;
    color: #999;
    margin-bottom: 10px;
}

.spinner-border {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.text-primary {
    color: #1da1f2 !important;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}